import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import styled, { css } from 'styled-components';
import {
  mainColor,
  placeholderColor,
  breakpoint,
  primaryButtonStyle,
} from '../../theme';
import { RouteComponentProps } from '@reach/router';
import { CROCCHA_API } from '../../../utils';
import { navigate } from '@reach/router';

type FormData = {
  name: string;
  email: string;
  subject: string;
  body: string;
};

type Res = {
  result: 'success' | 'failed';
};

type Status = 'standby' | 'success' | 'error' | 'working';

const useFetchPost = (url: string, success: () => void) => {
  const [submitStatus, setSubmitStatus] = useState<Status>('standby');

  const sendMail = (data: FormData) => {
    setSubmitStatus('working');
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((res: Res) => {
        if (res.result === 'success') {
          success();
          setSubmitStatus('success');
        } else {
          throw Error(res.result);
        }
      })
      .catch((e) => {
        console.error(e.message);
        setSubmitStatus('error');
      });
  };

  return { submitStatus, sendMail };
};

type ContactServiceFormProps = RouteComponentProps<{ service: string }>;

const ContactServiceForm: React.FC<ContactServiceFormProps> = ({ service }) => {
  const { handleSubmit, register, errors } = useForm<FormData>({
    mode: 'onChange',
  });
  const url = CROCCHA_API.ALB_HOST + '/contacts/company';
  const { sendMail, submitStatus } = useFetchPost(url, () =>
    navigate('/contact/success', { state: { fromPage: 'contact' } })
  );

  const onSubmit = (data: FormData) => {
    const submitData: FormData = {
      ...data,
      body: data.body?.replace(/\r?\n/g, '<br>') ?? '',
      subject: `${service}に関するお問い合わせ`,
    };
    sendMail(submitData);
  };

  return (
    <FormWrap>
      <P>{service}へのお問い合わせは下記フォームよりご連絡ください。</P>

      <form onSubmit={handleSubmit(onSubmit)}>
        <FieldWrap>
          <LabelWrap>
            <Label htmlFor='name'>
              お名前<Required>必須</Required>
            </Label>
            {errors?.name?.message && (
              <ErrorText>{errors.name.message}</ErrorText>
            )}
          </LabelWrap>
          <Input
            type='text'
            name='name'
            placeholder='例: 山田花子'
            ref={register({
              required: '名前を入力してください',
              maxLength: {
                value: 100,
                message: '100文字以内で入力してください',
              },
            })}
            hasError={errors?.name?.message}
          />
        </FieldWrap>

        <FieldWrap>
          <LabelWrap>
            <Label htmlFor='email'>
              メールアドレス<Required>必須</Required>
            </Label>
            {errors?.email?.message && (
              <ErrorText>{errors.email.message}</ErrorText>
            )}
          </LabelWrap>
          <Input
            type='text'
            name='email'
            placeholder='例: xxx@sample.jp'
            ref={register({
              required: '必須項目です',
              maxLength: {
                value: 100,
                message: '100文字以内で入力してください',
              },
              pattern: {
                value:
                  /^[a-zA-Z0-9.!#$%&'*+/=?^_{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                message: 'メールアドレスの形式が正しくありません',
              },
            })}
            hasError={errors?.email?.message}
          />
        </FieldWrap>

        <TextareaFieldWrap>
          <LabelWrap>
            <Label htmlFor='body'>
              お問い合わせ内容<Required>必須</Required>
            </Label>
            {errors?.body?.message && (
              <ErrorText>{errors.body.message}</ErrorText>
            )}
          </LabelWrap>
          <Input
            as='textarea'
            name='body'
            rows='10'
            placeholder={service === '採用' ? '希望職種等ご記載ください。' : ''}
            ref={register({
              required: '必須項目です',
              maxLength: {
                value: 700,
                message: '700文字以内で入力してください',
              },
            })}
            hasError={errors?.body?.message}
          />
        </TextareaFieldWrap>

        {submitStatus === 'error' && (
          <div>
            <p>お問い合わせを送信できませんでした。</p>
            <p>
              恐れ入りますがしばらくお待ちいただき、後ほどお試しいただけますでしょうか
            </p>
          </div>
        )}

        <SubmitButton type='submit' value='送信' />
      </form>
    </FormWrap>
  );
};

export default ContactServiceForm;

const FormWrap = styled.section`
  margin: 0 auto;
  max-width: 800px;
  padding: 0 1rem 60px;
  /* NOTE：メンテナンス時に有効にする */
  /* display: none; */
`;

const P = styled.p`
  text-align: center;
  margin-bottom: 40px;
`;

const LabelWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Label = styled.label`
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  white-space: nowrap;
`;

const Required = styled.span`
  margin-left: 5px;
  padding: 4px 8px;
  background: ${mainColor};
  border-radius: 11px;
  color: #fff;
  font-size: 12px;
  font-weight: normal;
  vertical-align: top;
`;

const ErrorText = styled.span`
  margin-bottom: 0.5rem;
  display: inline-block;
  color: ${mainColor};
  font-size: 12px;
`;

const FieldWrap = styled.div`
  margin-bottom: 43px;
  width: 70%;
  @media ${breakpoint.sp} {
    width: 100%;
  }
`;

const inputMixinStyle = css`
  display: block;
  padding: 0.5rem 1rem;
  width: 100%;
  background: #f8f8f8;
  border: 1px solid #a2a1a1;
  border-radius: 5px;
  &::placeholder {
    color: ${placeholderColor};
  }
  &:focus {
    border: 1px solid #d1a4a1;
    box-shadow: 0px 2px 6px #3c0e0e1f;
    background: #fff;
  }
  ${(props: any) =>
    props.hasError &&
    css`
      border: 1px solid ${mainColor};
      background: #fff5f5;
      box-shadow: 0px 2px 6px #3c0e0e1f;
      color: ${mainColor};
    `}
`;

const Input: any = styled.input`
  ${inputMixinStyle};
`;

const TextareaFieldWrap = styled.div`
  margin-bottom: 40px;
  width: 100%;
`;

const SubmitButton = styled.input`
  ${primaryButtonStyle};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 120px;
`;
