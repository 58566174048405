import * as React from 'react';
import styled, { css } from 'styled-components';
import {
  fontSize,
  mainColor,
  subTextColor,
  chevronStyle,
  textOverflowMultiline
} from '../../theme';
import Anchor from '../Anchor';

interface NewsCardProps {
  category: string;
  date: string;
  title: string;
  to: string;
}

const NewsCard: React.FC<NewsCardProps> = ({ category, date, title, to }) => (
  <Card>
    <StyledAnchor url={to}>
      {/* 上部 */}
      <NewsInfo>
        <Category>{category}</Category>
        <Date>{date}</Date>
      </NewsInfo>
      {/* タイトル */}
      <Title>{title}</Title>
      <More>続きを見る</More>
    </StyledAnchor>
  </Card>
);

export default NewsCard;

const Card = styled.div`
  height: 100%;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
  background: no-repeat 50px, 50px/80%,
    linear-gradient(
      to bottom right,
      transparent 0%,
      transparent 50%,
      #fff5f5 50%,
      #fff5f5 100%
    );
`;

const StyledAnchor = styled(Anchor)`
  display: block;
  height: 100%;
  padding: 1rem;
  &>div {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
`;

const NewsInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

export const newsCategoryStyle = css`
  padding: 0.5rem;
  text-align: center;
  border-radius: 25px;
  color: ${mainColor};
  border: 1px solid ${mainColor};
  font-size: 12px;
  font-weight: bold;
  white-space: nowrap;
`;

const Category = styled.div`
  ${newsCategoryStyle};
`;

const Date = styled.time`
  font-size: ${fontSize.small};
  color: ${subTextColor};
  white-space: nowrap;
`;

const Title = styled.div`
  margin-bottom: 1rem;
  ${textOverflowMultiline};
  -webkit-line-clamp: 4;
  text-align: left;
  font-weight: bold;
`;

const More = styled.div`
  margin-top: auto;
  text-align: right;
  color: ${subTextColor};
  font-size: 12px;
  font-weight: bold;

  &::after {
    ${chevronStyle};
    width: 8px;
    height: 8px;
    top: 5px;
    margin-left: 5px;
    border-color: ${subTextColor};
    transform: rotate(45deg);
  }
`;
