import React from 'react';
import BaseLayoutTemplate from '../components/environments/BaseLayoutTemplate';
import styled from 'styled-components';
import { bgColor, triangleStyle, breakpoint } from '../components/theme';
import PageTitle from '../components/atoms/PageTitle';
import {
  PhilosophyPc,
  PhilosophySp,
  CROCCHA_API,
  MetaDesc,
  MetaOGP,
} from '../utils';
import useMedia from '../customHooks/userMedia';
import HeadBase from '../components/molecules/HeadBase';
import { newsCategoryStyle } from '../components/atoms/NewsCard';
import Anchor from '../components/atoms/Anchor';
import ContactServiceForm from '../components/organisms/ContactSearviceForm';

const RecruitPage = () => {
  const isMobile = useMedia('(max-width: 767px)');
  return (
    <BaseLayoutTemplate>
      <HeadBase
        metas={{
          title: '採用情報 | tryangle株式会社',
          description: MetaDesc,
          openGraph: {
            type: 'article',
            url: `${CROCCHA_API.SELF_DOMAIN}/philosophy/`,
            title: '採用情報 | tryangle株式会社',
            description: MetaDesc,
            images: [
              {
                url: MetaOGP,
                width: 1200,
                height: 630,
                alt: 'tryangle株式会社',
              },
            ],
            site_name: 'tryangle株式会社',
          },
        }}
      />

      {/* タイトル */}
      <PageTitle
        url={isMobile ? PhilosophySp : PhilosophyPc}
        title='Recruit'
        span='採用情報'
      />

      <TopSection>
        <RecruitContainer>
          <p>
            ともに働く方を募集しております。
            <br />
            エンジニア、デザイナー含め全員が戦略を理解し、お客様の要望を応えるため
            <br />
            一人一人がプロフェッショナルとして各分野で活躍しています。
            <br />
            共に働くメンバーに敬意を払い誰もが家族・友人に胸を張って
            <br />
            会社や仕事を紹介できる、 そんな会社でありたいと思っています。
            <br />
            Mission、Vision、Valueに共感して頂ける方はぜひご応募お待ちしております。
          </p>
          <Name>代表取締役　藤原 真吾</Name>

          <Recruit>募集職種</Recruit>
          <RecruitList>
            <RecruitItem>
              <div>エンジニア</div>
            </RecruitItem>
            <RecruitItem>
              <div>マーケティング</div>
            </RecruitItem>
            <RecruitItem>
              <div>Webデザイナー</div>
            </RecruitItem>
            <RecruitItem>
              <div>営業</div>
            </RecruitItem>
            <RecruitItem>
              <div>事務</div>
            </RecruitItem>
          </RecruitList>
        </RecruitContainer>
      </TopSection>

      {/* お問い合わせ */}
      <Section>
        <h2>お問い合わせ</h2>
        <ContactServiceForm service='採用' />
      </Section>
    </BaseLayoutTemplate>
  );
};

export default RecruitPage;


const TopSection = styled.section`
  padding: 3rem 0;
  text-align: center;
  background: ${bgColor};
  padding-bottom: 100px;
`;

const Section = styled.section`
  padding: 3rem 0;
  text-align: center;

  h2 {
    margin-bottom: 32px;
    font-size: 24px;
  }
`;

const Name = styled.div`
  margin: 1rem 0 100px;
  color: #5d5b5b;
  font-weight: bold;
`;

const Recruit = styled.h5`
  ${newsCategoryStyle};
  width: 200px;
  margin: 0 auto 50px;
  font-size: 1rem;
`;

const RecruitContainer = styled.div`
  margin: 0 auto;
  padding: 0 2rem;
  max-width: 744px;
`;

const RecruitList = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  @media ${breakpoint.sp} {
    flex-direction: column;
  }
`;

const RecruitItem = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 43px;
  width: 150px;
  height: 150px;
  ${triangleStyle};
  :nth-child(3n-1) {
    margin-left: 70px;
    margin-right: 70px;
  }
  > div {
    font-weight: bold;
  }
`;

const FormLink = styled(Anchor)`
  > * {
    text-decoration: underline;
  }
`;
